<template>
  <div>
    <object :data="link" type="application/pdf" width="100%" height="900px">
      <p>
        Unable to display PDF file.
        <a :href="link">Download</a>
        instead.
      </p>
    </object>
  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang=""></style>
