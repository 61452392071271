import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    procedures: [
      {
        name: "<strong>Klinická výživa v onkologii:</strong><br> Diagnostika podvýživy, grading ztráty hmotnosti, role nutričního terapeuta, sipping v onkologii, enterální sondová výživa",
        romanNumber: "I",
        id: 38,
        vimeo: "https://player.vimeo.com/video/833266489?h=1409479308&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        secondaryMaterial: "https://general-storage.b-cdn.net/Onkonutrice/diagnostika%20podvy%CC%81z%CC%8Civy%20sekundra%CC%81ni%CC%81%20I.pdf",
        secondaryMaterialII: "https://general-storage.b-cdn.net/Onkonutrice/diagnostika%20podvy%CC%81z%CC%8Civy%20sekunda%CC%81rni%CC%81%20II.pdf"
      },
      {
        name: "<strong>Klinická výživa v onkologii:</strong><br> Parenterální výživa, žilní vstupy, perioperační nutriční podpora",
        romanNumber: "II",
        id: 40,
        primaryMaterial:
          "https://general-storage.b-cdn.net/Onkonutrice/parenta%CC%81lni%CC%81%20vy%CC%81z%CC%8Civa%20prima%CC%81rni%CC%81.pdf",
        secondaryMaterial:
          "https://general-storage.b-cdn.net/Onkonutrice/parenta%CC%81lni%CC%81%20vy%CC%81z%CC%8Civa%20sekundra%CC%81ni%CC%81.pdf",
      },
      {
        name: "<strong>Klinická výživa v onkologii:</strong><br> Paliativní chemoterapie, paliativní symptomatická péče, podpůrná léčba při malnutrici",
        romanNumber: "III",
        id: 41,
        primaryMaterial:
          "https://general-storage.b-cdn.net/Onkonutrice/paliativni%CC%81%20chemoterapie%20prima%CC%81rni%CC%81.pdf",
        secondaryMaterial:
          "https://general-storage.b-cdn.net/Onkonutrice/paliativni%CC%81%20chemoterapie%20sekundra%CC%81ni%CC%81.pdf",
      }
    ],
    literatures: [
      {
        name: "Výživa onkologického pacienta – přehled studií",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1XoauSyCqeaZQI0HYEcbgPvbIGNol9s2h",
        googleCode: "1344smmhqrDh-BQLQC_abS57EEniSm__w",
      },
      {
        name: "Dotazník hodnocení nutričního rizika",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1PcVJg_i8r5WMmOf7yB6nm3MUjeTe7do6",
        googleCode: "16E2gN7ELkbdp5IlDkMuEjtX6Nw0IETkr",
      },
      {
        name: "Nutriční doporučení",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1aN5SyiVZH-jDQSYk5KRi4cJRY-t9hFJi",
        googleCode: "19mJjlivRHfGO1ibj1EAjzLESFAScfG0u",
      },
      {
        name: "Tekutá enterální výživa k popíjení. Sipping",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1H5OLU1IQz95qF0VxzovJzTRfBz_L_mJY",
        googleCode: "1b61S7OKBNHEhWM9hHszZ8Cp0pW5KgZva",
      },
      {
        name: "Výživná dieta pro onkologického pacienta",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1PWQ9uncF1wK0thEHSyf6eDPhkDf4yshs",
        googleCode: "1Ps9R1-F6eYXpbxHv91f2xkrHVBMVn-EL",
      },
      {
        name: "Schema postupu řešení nutričních rizik",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1A_faTRFvX36gpJkmQZLHm5ccGLrww1VF",
        googleCode: "1aMZojeMGP51A_t3NbBsCECK9n7pow28T",
      },
      {
        name: "Modrá kniha ČOS",
        thumbnail:
          "https://drive.google.com/uc?export=view&id=1dO34qJ7q-t65GVWCfIuhx3eW1KqxfWWk",
        googleCode: null,
        url: "https://www.linkos.cz/lekar-a-multidisciplinarni-tym/personalizovana-onkologie/modra-kniha-cos/aktualni-vydani-modre-knihy/27-30-indikace-nutricni-podpory-onkologicky-nemocnych/",
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
