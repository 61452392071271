<template>
  <div id="video-intro" class="d-lg-flex justify-space-between inner">
    <div class="video-intro__text">
      <h3>Dobrý den,</h3>
      <p>
        vítejte na stránkách aplikace onkonutrice.cz! Cílem této aplikace je
        zvýšit povědomí a vzdělání lékařů o možnostech klinické výživy v
        onkologii. V případě onkologického onemocnění u pacienta je správná
        nutrice jedním z nejdůležitějších faktorů pro zvýšení šancí na úspěšné
        vyléčení.
      </p>
      <p>
        Věříme, že následující stránky Vám, jakožto lékaři, pomohou rozvinout
        Vaše dosavadní znalosti o této problematice a tím v budoucnu přispějí ke
        zlepšení celkového stavu populace onkologických pacientů.
      </p>
    </div>
    <div class="video-intro__video">
      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          src="https://player.vimeo.com/video/640402485?h=f380425e77&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          title="onkonturice_video"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
500

<style lang="scss" scoped>
#video-intro {
  padding: 4rem 1rem;

  p {
    text-align: justify;
  }
}

@media screen and (min-width: 1264px) {
  .video-intro__video {
    min-width: 40rem;
  }

  .video-intro__text {
    margin-right: 8rem;
  }
}
</style>
