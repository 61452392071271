<template>
  <div v-if="detail" id="nutrieducation-detail">
    <div class="inner">
      <div class="d-md-flex justify-space-between align-center">
        <div v-html="detail.name" class="mb-4"></div>
        <TestButton></TestButton>
      </div>

      <v-tabs v-model="tab" bg-color="primary" class="mb-4">
        <v-tab>Primární materiál</v-tab>
        <v-tab
          >Sekundární materiál
          <div class="ml-1" v-if="detail.vimeo">I.</div></v-tab
        >
        <v-tab v-if="detail.vimeo">Sekundární materiál II.</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div v-if="detail.vimeo">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                :src="detail.vimeo"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Role nutričního terapeuta_Věra Andrášková"
              ></iframe>
            </div>
          </div>
          <div v-else>
            <PdfViewer :link="detail.primaryMaterial"></PdfViewer>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div>
            <PdfViewer :link="detail.secondaryMaterial"></PdfViewer>
          </div>
        </v-tab-item>
        <v-tab-item v-if="detail.secondaryMaterialII">
          <div>
            <PdfViewer :link="detail.secondaryMaterialII"></PdfViewer>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../components/PdfViewer.vue";
import TestButton from "../components/TestButton.vue";

export default {
  name: "NutrieducationDetail",
  components: { PdfViewer, TestButton },
  data() {
    return {
      procedures: this.$store.state.procedures,
      detail: null,
      tab: 0,
    };
  },

  mounted() {
    this.detail = this.procedures.find(
      (procedure) => procedure.id === this.nutrieducatonId
    );
  },
  computed: {
    nutrieducatonId() {
      const params = this.$route.params.id;
      return parseInt(params);
    },

    testButtonIsDisabled() {
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
#nutrieducation-detail {
  .inner {
    padding-top: 7rem;
  }
}
</style>
