import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/assets/scss/variables.scss"],

  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#40bced",
      },
    },
  },
});
