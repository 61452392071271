<template>
  <div id="recommended-procedures-detail">
    <div class="inner">
      <h1>Doporučené postupy</h1>

      <v-row class="mb-4">
        <v-col cols="2" class="text-left">
          <v-btn
            v-if="previousProcedure"
            text
            color="primary"
            @click="goToProcedure(previousProcedure.id)"
            ><v-icon left>mdi-chevron-left</v-icon> Předchozí</v-btn
          >
        </v-col>
        <v-col cols="8" class="text-center">
          <h4>Blok {{ procedure.romanNumber }}: {{ procedure.name }}</h4>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            v-if="nextProcedure"
            text
            color="primary"
            @click="goToProcedure(nextProcedure.id)"
          >
            Další <v-icon right>mdi-chevron-right</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <div class="d-md-flex justify-space-between">
        <div class="iframe-wrapper mr-4">
          <iframe
            width="100%"
            height="900"
            :src="`https://drive.google.com/file/d/${[
              procedure.brochureDocument,
            ]}/preview`"
            @load="iFrameIsLoading = false"
          ></iframe>
          <v-overlay absolute :value="iFrameIsLoading">
            <v-progress-circular
              indeterminate
              color="primary"
              size="100"
            ></v-progress-circular>
          </v-overlay>
        </div>
        <div class="iframe-wrapper">
          <iframe
            width="100%"
            height="900"
            :src="`https://drive.google.com/file/d/${[
              procedure.textDocument,
            ]}/preview`"
            @load="iFrameIsLoading = false"
          ></iframe>
          <v-overlay absolute :value="iFrameIsLoading">
            <v-progress-circular
              indeterminate
              color="primary"
              size="100"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecommendedProceduresDetail",
  data() {
    return {
      iFrameIsLoading: false,
    };
  },
  computed: {
    procedure() {
      return this.$store.state.procedures.find(
        ({ id }) => id === parseInt(this.$route.params.id)
      );
    },
    nextProcedure() {
      return this.$store.state.procedures.find(
        ({ id }) => id === parseInt(this.$route.params.id) + 1
      );
    },
    previousProcedure() {
      return this.$store.state.procedures.find(
        ({ id }) => id === parseInt(this.$route.params.id) - 1
      );
    },
  },
  methods: {
    goToProcedure(id) {
      this.iFrameIsLoading = true;
      this.$router.push({ params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
#recommended-procedures-detail {
  padding-top: 8rem;
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  h1 {
    margin-bottom: 4rem;
  }

  .video-wrapper {
    max-width: 55rem;
    margin: auto;

    video {
      cursor: pointer;
    }
  }

  .iframe-wrapper {
    position: relative;
    margin-bottom: 4rem;
    min-width: 50%;
  }
}
</style>
