<template>
  <div id="congresses">
    <div class="inner">
      <h1>Vzdělávací akce</h1>
      <congress-table />
    </div>
  </div>
</template>

<script>
import CongressTable from '../components/CongressTable.vue';
export default {
  components: { CongressTable },};
</script>

<style lang="scss" scoped>
#congresses {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  h1 {
    margin-bottom: 4rem;
  }
  .inner {
    padding-top: 8rem;
  }
}
</style>