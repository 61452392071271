<template>
  <div id="landing-page">
    <page-header />
    <video-intro />
    <promo />
    <congress-table-section />
  </div>
</template>

<script>
import CongressTableSection from '../components/CongressTableSection.vue';
import PageHeader from "../components/PageHeader.vue";
import Promo from "../components/Promo.vue";
import VideoIntro from "../components/VideoIntro.vue";
export default {
  components: { PageHeader, VideoIntro, Promo, CongressTableSection },
  name: "LandingPage",
};
</script>