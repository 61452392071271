import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "./plugins/axios";
import store from "./store";
import vuetify from "./plugins/vuetify";
import notifications from 'vue-notification'
import VueGtag from 'vue-gtag';
import { bootstrap } from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: 'G-1NP479D3TC',
  },
  enabled: false,
  bootstrap: false,
});

bootstrap().then(() =>
  window.gtag('config', 'G-8W5S90C0FC', { debug_mode: true })
);


[axios, notifications].forEach((x) => Vue.use(x));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
