<template>
  <div id="work-places">
    <div class="inner">
      <h1>Přehled nutričních ambulancí</h1>
      <div class="iframe-wrapper">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1UThbHlbDMpw6YGh7Vsn9UOTVqxEE4QwE"
          width="100%"
          height="900"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#work-places {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .inner {
    padding-top: 8rem;
  }

  .iframe-wrapper {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
</style>
