<template>
  <div id="nutrieducation">
    <div class="inner">
      <h1>Nutriedukace</h1>
      <v-row class="mb-12">
        <v-col md="6" cols="12">
          <div class="mb-4">Vážení návštěvníci,</div>
          <p>
            vítáme Vás na stránkách onkonutrice.cz. Hlavním záměrem této sekce je
            rozšířit povědomí a odborné znalosti nutričních terapeutů o možnostech
            klinické výživy v oblasti onkologie. Jsme pevně přesvědčeni, že
            následující stránky Vám umožní prohloubit Vaše stávající znalosti v
            této oblasti a budou tak přispívat ke zlepšení celkového zdravotního
            stavu onkologické populace v budoucnosti.
          </p>
          <p>
            Naleznete zde k dispozici studijní materiály s časovým omezením pro jejich prostudování. Po časovém úseku 15
            minut lze přejít na testové otázky, jejichž absolvováním získáte certifikát s potvrzením o dokončení. Pro
            udělení certifikátu není stanoven minimální počet správných odpovědí.
          </p>
        </v-col>
        <v-col>
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/847264562?h=493ee53b68&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              " title="Andrášková_zdravice"></iframe>
          </div>
        </v-col>
      </v-row>

      <div id="recommended-procedures">
        <h2>
          Kurzy:
        </h2>
        <v-row>
          <v-col md="4" cols="12" v-for="procedure in $store.state.procedures" :key="procedure.name">
            <div class="procedure" @click="redirectToDetail(procedure.id)">
              <v-img class="procedure__placeholder" :src="require(`../assets/img/Presentation${procedure.id}.png`)">
                <template v-slot:placeholder>
                  <v-sheet height="100%">
                    <v-skeleton-loader type="image" />
                  </v-sheet>
                </template>
              </v-img>

              <div class="procedure__name pa-4" v-html="procedure.name"></div>

            </div>
          </v-col>
        </v-row>
      </div>

      <div>
        <div class="mt-12 mb-4">
          Garant kurzů
        </div>
        <v-row>
          <v-col md="2">
            <v-img src="https://general-storage.b-cdn.net/Onkonutrice/partner-1.png" max-width="200">
            </v-img>
          </v-col>

          <v-col>
            <v-img src="https://general-storage.b-cdn.net/Onkonutrice/partner-2.png" max-width="200" contain>
            </v-img>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    redirectToDetail(id) {
      this.$router.push({
        name: "NutrieducationDetail",
        params: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#nutrieducation {
  background-image: url("../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .inner {
    padding-top: 8rem;
  }
}

#recommended-procedures {
  .inner {
    padding: 8rem 1rem 1rem 1rem;
  }

  h1 {
    margin-bottom: 4rem;
  }

  .procedure {
    margin: 0 0 2rem 0;
    max-width: 100%;
    height: 100%;
    cursor: pointer;
    transition: box-shadow 0.4s;
    border: 1px solid gainsboro;
    background-color: whitesmoke;

    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: 1px solid gainsboro;

      .procedure__name {
        color: $primary;
      }
    }

    .procedure__placeholder {
      max-width: 100%;
      background-color: whitesmoke;
    }

    .procedure__name {
      font-size: 1.2rem;
      color: gray;
      transition: color 0.4s;
      background-color: whitesmoke;
      padding: 0.5rem;
    }
  }
}

@media screen and (min-width: 960px) {
  #recommended-procedures {
    .procedure {
      margin: 0 2rem 2rem 0;
    }

    .inner {
      padding: 8rem 0;
    }
  }
}
</style>
