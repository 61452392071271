<template>
  <v-btn
    :disabled="!isTimerDone"
    color="primary"
    depressed
    @click="$router.push({ name: 'NutrieducationTest' })"
  >
  
    <v-icon left>mdi-school-outline</v-icon> Přejít na test
    <span v-if="!isTimerDone" class="ml-2">{{ formattedMinutes }}:{{ formattedSeconds }}</span>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      minutes: 15,
      seconds: 0,
      timer: null,
      isTimerDone: false
    };
  },
  async mounted() {
    await this.retrieveTimerFromLocalStorage();
    this.startTimer();
  },
  computed: {
    formattedMinutes() {
      return this.padDigits(this.minutes, 2);
    },
    formattedSeconds() {
      return this.padDigits(this.seconds, 2);
    }
  },
  methods: {
    retrieveTimerFromLocalStorage() {
      const timerData = localStorage.getItem(`countdownTimer${this.$route.params.id}`);
      if (timerData) {
        const { minutes, seconds, courseId } = JSON.parse(timerData);
        if (parseInt(courseId) === parseInt(this.$route.params.id)) {
          this.minutes = minutes;
          this.seconds = seconds;
        } else {
          console.log(courseId);
          console.log(this.$route.params.id)
          // Reset the timer if the courseId does not match
          this.resetTimer();
        }
      }
    },
    saveTimerToLocalStorage() {
      const timerData = JSON.stringify({
        minutes: this.minutes,
        seconds: this.seconds,
        courseId: this.$route.params.id
      });
      localStorage.setItem(`countdownTimer${this.$route.params.id}`, timerData);
    },
    startTimer() {
      if(this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else if (this.minutes > 0) {
          this.minutes--;
          this.seconds = 59;
        } else {
          clearInterval(this.timer);
          this.isTimerDone = true;
          // Timer has ended
          // You can add your logic here
        }
        this.saveTimerToLocalStorage();
      }, 1000);
    },
    padDigits(value, digits) {
      return String(value).padStart(digits, '0');
    },
    resetTimer() {
      clearInterval(this.timer);
      this.minutes = 0;
      this.seconds = 15;

    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
