<template>
  <div id="promo" class="inner d-md-flex justify-space-between">
    <div class="card mr-md-4 mb-4 mb-md-0">
      <div class="icon medical"></div>
      <h3>Doporučené postupy</h3>
      <p>
        Zde najdete nejnovější odborná doporučení pro využití klinické výživy v
        onkologii.
      </p>
      <v-btn
        class="mt-4"
        depressed
        rounded
        outlined
        color="primary"
        @click="$router.push({ name: 'recommended-procedures' })"
        >Vstoupit</v-btn
      >
    </div>
    <div class="card">
      <div class="icon student"></div>
      <h3>E-learningové kurzy</h3>
      <p>
        Rozšiřte si své znalosti o klinické výživě a získejte kredity
        celoživotního vzdělávání od ČLK.
      </p>
      <v-btn
        class="mt-4"
        depressed
        rounded
        outlined
        color="primary"
        @click="navigateToEcme"
        >Vstoupit</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "promo",
  methods: {
    navigateToEcme() {
      open("https://www.medicinskevzdelavani.cz/");
    },
  },
};
</script>

<style lang="scss" scoped>
#promo {
  margin-bottom: 4rem;

  .icon {
    width: 100%;
    width: 2.5rem;
    height: 2.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1rem;

    &.medical {
      background-image: url("../assets/img/medical.svg");
    }
    &.student {
      background-image: url("../assets/img/student.svg");
    }
  }

  .v-btn {
    height: 2.5rem;
    font-size: 1rem;
    transition: background-color 0.8s ease;
    padding: 1.2rem;
    border: 2px solid $primary;

    &:hover {
      background-color: $primary;
      ::v-deep .v-btn__content {
        color: white;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .card {
    max-width: 50%;
  }
}
</style>
