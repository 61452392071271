<template>
  <div id="app-bar">
    <v-app-bar :elevate-on-scroll="landing" fixed :color="landing ? '' : 'white'"
      :height="$vuetify.breakpoint.smAndDown ? 65 : 64">
      <div class="inner d-flex align-center">
        <div class="logo" @click="$router.push({ name: 'landing-page' })">
          <v-img src="../assets/img/eleon.png" :width="$vuetify.breakpoint.smAndDown ? 170 : logoWidth"></v-img>
        </div>
        <v-spacer />

        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click="drawer = !drawer" />

        <div v-else class="d-flex align-center">
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="app-bar-item" v-bind="attrs" v-on="on" text>
                E-learningové kurzy
              </div>
            </template>

            <v-list>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item @click="redirect">
                  <v-list-item-title>Pro lékaře</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ path: '/nutriedukace' })">
                  <v-list-item-title>Pro nutriční terapeuty</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <div class="app-bar-item" v-for="appBarItem in appBarItems" :key="appBarItem.name"
            @click="onAppBarItem(appBarItem.action)">
            {{ appBarItem.name }}
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed right>
      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title link @click="redirect">Kurzy pro lékaře</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title @click="$router.push({ path: '/nutriedukace' })"> Kurzy pro nutriční terapeuty
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-for=" appBarItem in appBarItems" :key="appBarItem.name" link
          @click="onAppBarItem(appBarItem.action)">
          <v-list-item-content>
            <v-list-item-title>{{ appBarItem.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  data() {
    return {
      scrollWidth: 250,
      drawer: false,
      selectedItem: null,
      appBarItems: [
        {
          name: "Přehled nutričních ambulancí",
          action: "workplaces",
        },
        {
          name: "Literatura",
          action: "literatures",
        },
        {
          name: "Vzdělávací akce",
          action: "congresses",
        },
        {
          name: "PSNPO",
          action:
            "https://www.linkos.cz/ceska-onkologicka-spolecnost-cls-jep/organizace-cos/pracovni-skupiny-cos/pracovni-skupina-nutricni-pece-v-onkologii-pri-cos/",
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    landing() {
      return this.$route.name === "landing-page";
    },
    logoWidth() {
      const width = this.landing ? this.scrollWidth : 150;
      return width;
    },
  },
  methods: {
    redirect() {
      window.open("https://medicinskevzdelavani.cz/", "_blank");
    },
    onAppBarItem(action) {
      if (this.$route.name === action) return;
      action.includes("https")
        ? open(action)
        : this.$router.push({ name: action });
    },
    handleScroll() {
      if (window.scrollY === 0) {
        this.scrollWidth = 250;
      } else {
        this.scrollWidth = 150;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app-bar {
  ::v-deep .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  }

  .app-bar-item {
    text-decoration: none;
    font-size: 1.1rem;
    color: $font-color;
    cursor: pointer;
    font-size: 1rem;

    &:not(:last-of-type) {
      margin-right: 2rem;
    }

    &:hover {
      text-shadow: 0 0.015em #101010, 0 -0.015em #101010, 0.01em 0 #101010,
        -0.01em 0 #101010;
    }
  }

  .inner {
    padding: 0 1rem;
  }

  .logo {
    cursor: pointer;

    .v-image {
      transition: width 400ms;
      margin: 1rem 0;
    }
  }

  .v-app-bar--hide-shadow {
    background-color: $secondary;
  }

  .v-app-bar--is-scrolled {
    background-color: white;
  }
}
</style>
