<template>
  <v-app>
    <notifications />
    <v-main>
      <app-bar />
      <router-view />
      <cookies-setting :showCookiesModal="showCookiesModal" @onCookiesSetting="onCookiesSetting"
        @onSaveSettings="showCookiesModal = false" />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import CookiesSetting from "./components/CookiesSetting.vue";

export default {
  name: "App",
  components: {
    AppBar,
    CookiesSetting,
  },
  data() {
    return {
      showCookiesModal: false,
    };
  },
  methods: {
    onCookiesSetting() {
      this.showCookiesModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/index.scss";

.vue-notification {
  font-size: 1rem;
}
</style>
