<template>
  <div id="nutrieducation-test">
    <div class="inner">
      <v-card class="ma-auto mb-4" max-width="900" flat>
        <v-btn
          color="primary"
          text
          @click="
            $router.push({
              name: 'NutrieducationDetail',
              params: { id: $route.params.id },
            })
          "
          ><v-icon left>mdi-chevron-left</v-icon> Zpět na materiál</v-btn
        >
      </v-card>
      <v-form v-if="courseTest" ref="form" v-model="valid">
        <v-card class="pa-8 ma-auto mb-4" max-width="900">
          <div class="text-h6 mb-4">Údaje pro certifikát</div>
          <div class="text-body-1 mb-4">
            Pro získání certifikátu za absolvování kurzu, prosím, vyplňte
            následující údaje:
          </div>
          <v-text-field
            outlined
            dense
            label="Jméno a přijmení a tituly"
            v-model="participant.fullName"
          >
          </v-text-field>
          <v-text-field
            outlined
            dense
            label="Email"
            v-model="participant.email"
          >
          </v-text-field>
        </v-card>
        <v-card v-if="courseTest" class="pa-8 ma-auto" max-width="900">
          <v-radio-group
            v-for="(test, i) in courseTest.test"
            :key="i"
            v-model="test.userAnswer"
            class="mt-0 mb-4"
            :rules="rules"
          >
            <template v-slot:label>
              <div class="text-h6">{{ test.id }} {{ test.question }}</div>
            </template>
            <v-radio
              v-for="(answer, i) in test.answers"
              :key="i"
              :value="answer"
            >
              <template v-slot:label>
                <div>
                  {{ answer.text }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>

          <div class="text-center mt-12">
            <div v-if="missingAnswer" class="error-message">
              nejsou vyplněné všechny odpovědi
            </div>
            <v-btn
              depressed
              :disabled="missingAnswer"
              color="primary"
              @click="submitTest"
              :loading="loading"
            >
              Vyhodnotit test
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </div>

    <v-dialog max-width="500" v-model="showDialog" persistent>
      <div class="pa-8 white">
        <div class="mb-8">
          <div class="text-h5 mb-2">Vyhodnocení testu</div>
          <div class="mb-4">
            <strong>{{ response.course.name }}</strong>
          </div>
          <div class="mb-2">
            Vaše úspěšnost testu je: <strong>{{ scorePercentage }} %</strong>
          </div>
          <div v-if="participant.email && participant.fullName">
            <div class="mb-2">
              Certifikát si můžete stáhnout
              <a :href="response.certificateFileUrl" target="_blank"
                ><strong>ZDE</strong>
              </a>
            </div>
            <div>
              Také jsme Vám ho zaslali na email: {{ participant.email }}
            </div>
          </div>
        </div>
        <div>
          <v-btn text class="mr-2" @click="resetTest">
            Vyplnit test znovu
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="$router.push({ name: 'nutrieducation' })"
          >
            Zpět na seznam kurzů
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import tests from "@/data/tests.json";

export default {
  data() {
    return {
      valid: true,
      courseTest: null,
      loading: false,
      showDialog: false,
      participant: {
        email: null,
        fullName: null,
      },
      rules: [(value) => !!value || "Prosím odpovězte na otázku"],
      response: {
        course: {
          name: null,
        },
      },
    };
  },

  mounted() {
    this.getCourseTest();
  },

  computed: {
    scorePercentage() {
      const userTest = this.courseTest?.test;
      if (!this.courseTest) {
        return;
      }
      const userAnswers = userTest.map((e) => e.userAnswer);
      const countOfCorrect = userAnswers.filter(
        (e) => e?.isCorrect === true
      ).length;
      const countOfAnswers = userAnswers.length;
      return parseInt((countOfCorrect / countOfAnswers) * 100);
    },

    missingAnswer() {
      const userTest = this.courseTest?.test;
      if (!this.courseTest) {
        return;
      }
      const userAnswers = userTest.map((e) => e.userAnswer);
      const arrayHasNull = userAnswers.some((e) => e === null);
      return arrayHasNull;
    },

    courseId() {
      switch (parseInt(this.$route.params.id)) {
        case 37:
          return 1;
        case 38:
          return 2;
        case 39:
          return 3;
        case 40:
          return 4;
        case 41:
          return 5;
        default:
          return 0;
      }
    },
  },

  methods: {
    getCourseTest() {
      const currentTests = JSON.parse(JSON.stringify(tests));
      this.courseTest = currentTests.find(
        (test) => test.id === parseInt(this.$route.params.id)
      );
    },

    resetTest() {
      this.getCourseTest();
      this.$refs.form.resetValidation();
      this.participant.fullName = null,
      this.participant.email = null,
      this.showDialog = false;
    },

    async submitTest() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      try {
        this.loading = true;
        const payload = {
          testAttempt: {
            courseId: this.courseId,
            scorePercentage: this.scorePercentage,
            form: JSON.stringify(this.courseTest),
            participant: this.participant,
          },
        };

        if (!this.participant.email || !this.participant.fullName) {
          payload.testAttempt.participant = null;
        }
        ({
          data: { testAttempt: this.response },
        } = await this.$axios.post("tests", payload));
        console.log(this.response);
        this.showDialog = true;
      } catch (error) {
        const errors = error.response.data.errors;
        errors.forEach((e) => {
          this.$notify({
            type: "error",
            title: e.message,
            duration: 7000,
          });
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#nutrieducation-test {
  .inner {
    padding-top: 7rem;
  }

  .v-card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .error-message {
    color: red;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
